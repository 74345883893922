import React from 'react'
import Wrapper from '../components/wrapper'
import SEO from '../components/seo'
import Body from '../components/body'
import Header from '../components/header'
import ResourceContainer from '../components/resourceContainer'
import ContactSnippet from '../components/contactSnippet'
import Footer from '../components/footer'

const ResourcesPage = () => (
    <Wrapper>
      <SEO title="Resources" />
      <Header />
      <Body>
        <ResourceContainer />
        <ContactSnippet />
      </Body>
      <Footer />
    </Wrapper>
)

export default ResourcesPage
