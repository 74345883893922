import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Styled from 'styled-components'
import Resource from './resource'

const Container = Styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    margin: 5px;

    @media screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`

const ResourceContainer = () => (
    <StaticQuery 
        query={graphql`
            query {
                aasect: file(relativePath: { eq: "logo-aasect.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                centerforhealthysex: file(relativePath: { eq: "logo-centerforhealthysex.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                dameproducts: file(relativePath: { eq: "logo-dameproducts.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                genderspectrum: file(relativePath: { eq: "logo-genderspectrum.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                goodvibes: file(relativePath: { eq: "logo-goodvibes.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                plannedparenthood: file(relativePath: { eq: "logo-plannedparenthood.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                siecus: file(relativePath: { eq: "logo-siecus.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                stdtesting: file(relativePath: { eq: "logo-stdtesting.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                wpath: file(relativePath: { eq: "logo-wpath.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => (
            <Container>
                <Resource 
                    imgSrc={data.aasect.childImageSharp.fluid} 
                    href="https://www.aasect.org/"
                    description="Founded in 1967, the American Association of Sexuality Educators, Counselors and Therapists (AASECT) is devoted to the promotion of sexual health by the development and advancement of the fields of sexual therapy, counseling, and education. AASECT's mission is the advancement of the highest standards of professional practice for educators, counselors and therapists."
                    bg="#7BC3DA" 
                    color="#fff"
                />
                <Resource 
                    imgSrc={data.centerforhealthysex.childImageSharp.fluid} 
                    href="https://centerforhealthysex.com/" 
                    description="Center for Healthy Sex is a certified team of dedicated professionals specializing in sex addiction treatment and sex therapy. Our sex therapists have over 14 years of experience treating issues such as sexual dysfunction, porn addiction, love addiction, and sex addiction in Los Angeles. We provide both individual and couple therapy. We have helped people from all walks of life: young and old, men and women, and people from various backgrounds. We respect all races, religions, sexual preferences and orientations." 
                />
                <Resource 
                    imgSrc={data.dameproducts.childImageSharp.fluid} 
                    href="https://www.dameproducts.com/" 
                    description="Dame Products engineers for sexual wellness by building welcoming community, designing innovative tools, and bringing life-changing education to your bedroom." 
                />
                <Resource 
                    imgSrc={data.genderspectrum.childImageSharp.fluid} 
                    href="https://www.genderspectrum.org/" 
                    description="Gender Spectrum works to create gender sensitive and inclusive environments for all children and teens." 
                />
                <Resource 
                    imgSrc={data.goodvibes.childImageSharp.fluid} 
                    href="https://www.goodvibes.com/" 
                    description="Good Vibrations is the premiere sex-positive adult toy company in the US. Our customers trust us to provide education, high quality products, and information promoting sexual health, pleasure, and empowerment. We are proud of the model we innovated: creating a safe, welcoming, and non-judgmental environment where customers can shop for sex toys and books, as well as attend workshops and inspiring events." 
                />
                <Resource 
                    imgSrc={data.plannedparenthood.childImageSharp.fluid} 
                    href="https://www.plannedparenthood.org/" 
                    description="Planned Parenthood is America’s most trusted provider of reproductive health care. Our skilled health care professionals are dedicated to offering all people high-quality, affordable medical care. One in five American women has chosen Planned Parenthood for health care at least once in her life." 
                    bg="#7BC3DA" 
                    color="#fff"
                />
                <Resource 
                    imgSrc={data.siecus.childImageSharp.fluid} 
                    href="https://siecus.org/" 
                    description="SIECUS asserts that sex education is a powerful vehicle for social change. We view sexuality as a fundamental part of being human, one worthy of dignity and respect. We advocate for the rights of all people to accurate information, comprehensive sexuality education, and the full spectrum of sexual and reproductive health services." 
                    bg="#7BC3DA" 
                    color="#fff"
                />
                <Resource 
                    imgSrc={data.stdtesting.childImageSharp.fluid} 
                    href="https://www.stdtesting.org/std-testing/" 
                    description="At STDTesting.org, we offer people with free STD resources to make informed decisions about their sex life and connect them with affordable and private STD testing options to help stop the spread of STDs." 
                />
                <Resource 
                    imgSrc={data.wpath.childImageSharp.fluid} 
                    href="https://www.wpath.org/" 
                    description="To promote evidence based care, education, research, public policy, and respect in transgender health." 
                />
            </Container>
        )}
    />
)

export default ResourceContainer 
