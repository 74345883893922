import React from 'react'
import Img from 'gatsby-image'
import Styled from 'styled-components'

const Container = Styled.a`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
    text-decoration: none;
    color: #000;
    text-align: center;
    height: 50vw;
    box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
    
    @media screen and (min-width: 720px) {
        height: 33vw;
    }

    @media screen and (min-width: 900px) {
        height: 225px;
    }
`
const Logo = Styled(Img)`
    width: 90%;
`
const Description = Styled.p`
    font-size: 14px;
    text-align: justify;
`
const Blend = Styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
`

const Resource = ({ imgSrc=null, href, description, bg='#fff', color='#000' }) => {
    return (
        <Container style={{ background: `${bg}` }} href={href} target='_BLANK'>
            <Logo fluid={imgSrc} alt='Resource logo.' />
            <Description style={{ color: `${color}` }}>{description}</Description>
            <Blend style={{ background: `linear-gradient( transparent, ${bg} )` }} />
        </Container>
    )
}

export default Resource
